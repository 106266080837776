var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{ref:"profile_form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS))?_c('base-input',{attrs:{"label":((_vm.$t('COMMON.ORGANIZATION')) + " (*)"),"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"allowNone":true,"organization":_vm.purchasesInvoice.organization.id,"filterable":true,"showAll":false,"disabled":!!_vm.purchasesInvoice.id},on:{"organizationChanged":function (organizationId) {
          _vm.purchasesInvoice.organization.id = organizationId;
          _vm.purchasesInvoice.allowedLocations = [];
          _vm.purchasesInvoice.purchasesOrder.id = null;
          _vm.purchasesInvoice.issuer.id = null;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS))?_c('base-input',{attrs:{"label":("" + (_vm.$t('COMMON.LOCATION'))),"placeholder":_vm.$t('COMMON.LOCATION')}},[_c('locations-selector',{attrs:{"locations":_vm.purchasesInvoice.allowedLocations,"filterable":true,"showAll":false,"multiple":true,"organization":_vm.purchasesInvoice.organization.id},on:{"locationsChanged":function (locations) {
          _vm.purchasesInvoice.allowedLocations = locations;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.location}}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_PURCHASES_ORDERS))?_c('base-input',{attrs:{"label":("" + (_vm.$t('COMMON.PURCHASES_ORDERS'))),"placeholder":_vm.$t('COMMON.PURCHASES_ORDERS')}},[_c('purchases-order-selector',{attrs:{"allowNone":true,"purchasesOrder":_vm.purchasesInvoice.purchasesOrder.id,"filterable":true,"showAll":false,"disabled":!!_vm.purchasesInvoice.id,"filterOrganization":_vm.purchasesInvoice.organization.id,"filterStatus":_vm.ORDER_STATUS_VALIDATED},on:{"purchasesOrderChanged":function (purchasesOrderId, purchasesOrder) {
          _vm.purchasesInvoice.purchasesOrder.id = purchasesOrderId;
          if (purchasesOrder) {
            _vm.purchasesInvoice.issuer.id = purchasesOrder.issuer.id;
          }
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}}),_c('base-input',{attrs:{"label":((_vm.$t('PURCHASES_INVOICES.EXPIRATION_TIME')) + " (*)"),"placeholder":_vm.$t('PURCHASES_INVOICES.EXPIRATION_TIME')}},[_c('flat-picker',{staticClass:"form-control datepicker",attrs:{"config":{
        allowInput: true,
        minDate: 'today',
        locale: _vm.$flatPickrLocale(),
      }},on:{"on-change":function () {
          _vm.onFormChanged();
        }},model:{value:(_vm.purchasesInvoice.expiration_time),callback:function ($$v) {_vm.$set(_vm.purchasesInvoice, "expiration_time", $$v)},expression:"purchasesInvoice.expiration_time"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.expiration_time}}),_c('base-input',{attrs:{"label":((_vm.$t("PURCHASES_INVOICES.SELECT_SUPPLIERS")) + " (*)")}},[_c('supplier-selector',{attrs:{"allowNone":true,"supplier":_vm.purchasesInvoice.issuer.id,"filterable":true,"showAll":false,"filterOrganization":_vm.purchasesInvoice.organization.id,"disabled":!!_vm.purchasesInvoice.id},on:{"supplierChanged":function (supplierId) {
          _vm.purchasesInvoice.issuer.id = supplierId;
          _vm.onFormChanged();
        }}})],1),_c('h2',{staticClass:"mt-5"},[_vm._v(_vm._s(_vm.$t("COMMON.NOTE")))]),_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.purchasesInvoice.excerpt),callback:function ($$v) {_vm.$set(_vm.purchasesInvoice, "excerpt", $$v)},expression:"purchasesInvoice.excerpt"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.excerpt}}),_c('div',{staticClass:"my-4"},[_c('base-button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.purchasesInvoice.id ? _vm.$t("PURCHASES_INVOICES.EDIT_PURCHASES_INVOICE") : _vm.$t("PURCHASES_INVOICES.ADD_PURCHASES_INVOICE"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }